<template>
    <div class="toast fade" ref="toastRef">
        <slot v-if="toastOptions?.noProgressBar"></slot>
        <div v-else class="m-0 py-2 alert"
            :class="`alert-${type ?? 'custom'}`" 
            role="alert" ref="contentContainerRef"
            aria-live="assertive" aria-atomic="true">
            <slot></slot>
            <div v-if="props.bootstrapOptions.autohide" class="progress customMargins" role="progressbar" style="height: 5px" :aria-valuenow="computedDelay" aria-valuemin="0" :aria-valuemax="bootstrapOptions.delay">
                <div class="progress-bar" :class="`bg-${type ?? 'custom'}`" :style="{width: percentageDelay + '%'}"></div>
            </div>
        </div>  
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps<{
    bootstrapOptions?: any;
    toastOptions: any;
    remove: Function;
    type?: string;
}>();

function handleToastHide() {
    if (props.toastOptions?.onRemove) {
        props.toastOptions.onRemove();
    }
};

function handleToastHidden() {
    window.setTimeout(() => {
        props.remove();
    }, 500);
}

const toastRef = ref<HTMLElement | null>(null);

let toastInstance: any = null;
onMounted(() => {
    toastInstance = new window['bootstrap'].Toast(toastRef.value, { autohide: false });
    toastRef.value?.addEventListener('hide.bs.toast', handleToastHide);
    toastRef.value?.addEventListener('hidden.bs.toast', handleToastHidden);
    toastInstance?.show();
    handleAutoClose(toastInstance, props.bootstrapOptions.autohide, props.bootstrapOptions.delay);
});

onBeforeUnmount(() => {
    toastRef.value?.removeEventListener('hide.bs.toast', handleToastHide);
    toastRef.value?.removeEventListener('hidden.bs.toast', handleToastHidden);
    toastInstance?.dispose();
});

let computedDelay = ref(0);
let percentageDelay = ref(100);

function handleAutoClose(toastInstance: any, autohide: boolean, delay: number) {
    computedDelay.value = delay;
    if (autohide) {
        const interval = setInterval(() => {
            if (computedDelay.value > 0) {
                computedDelay.value -= 100;
                percentageDelay.value = Math.ceil((computedDelay.value - 100)/delay * 100);
            }
            if (computedDelay.value <= 0) {
                clearInterval(interval);
                setTimeout(() => {
                    if (toastInstance.isShown) {
                        toastInstance.hide();
                    }
                }, 1000)
            }
        }, 100)
    }
}

</script>

<style scoped>
.shake-animation {
    animation: shake 0.5s;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(-5px);
    }

    40% {
        transform: translateX(5px);
    }

    60% {
        transform: translateX(-5px);
    }

    80% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

.alert-custom {
    background-color: var(--custom-toast-color);
}

.bg-custom {
    background-color: var(--custom-toast-color);
}
</style>
